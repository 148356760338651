<template>
  <Layout>
    <v-col md="6" cols="12">
      <v-card class="pa-5" elevation="3">
        <RouterView :key="$route.fullPath" />
      </v-card>
    </v-col>
  </Layout>
</template>
<script>
import Layout from '@layouts/login.vue'

export default {
  name: 'login-box',
  components: {
    Layout,
  },
}
</script>
